import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default function ImageResponsive({
  imageObject,
  loading = "lazy",
  className = "",
  classNameImage = "",
}) {
  const image = getImage(imageObject.mobileImage);
  const imageAlt = imageObject.alt;

  const imageLarge = getImage(imageObject.image);
  return (
    <article>
      <div className={`${className} lg:hidden`}>
        <GatsbyImage
          image={image}
          alt={imageAlt}
          loading={loading}
          className={`${classNameImage} lg:hidden`}
        />
      </div>

      <div className={`${className} hidden lg:block`}>
        <GatsbyImage
          image={imageLarge}
          alt={imageAlt}
          loading={loading}
          className={`${classNameImage} hidden lg:block`}
        />
      </div>
    </article>
  );
}
