import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Section from "../components/Section";
import ImageResonsive from "../components/utilities/ImageResponsive.js";
import RenderMarkdown from "../components/utilities/RenderMarkdown";
import FLexGroup from "../components/utilities/FlexGroup";
import PreviewCompatibleImage from "../components/utilities/PreviewCompatibleImage";
import BrandButton from "../components/BrandButton";

const ImagesAndLinksPageTemplate = ({ data }) => {
  return (
    <>
      <Layout isWithHeader={false} seoObject={data.seoObject}>
        <header className="w-full">
          <ImageResonsive
            imageObject={data.headerObject.imageObject}
            className="w-full h-full"
            classNameImage="w-full h-full"
          />
        </header>
        <Section className="">
          <RenderMarkdown
            markdownContent={data.heroObject.title}
            className="text-brand-gold"
          />
          <FLexGroup
            ElementName={ImageAndLink}
            list={data.heroObject.imagesAndLinksList}
          />
        </Section>
      </Layout>
    </>
  );
};

function ImageAndLink({ imageObject, linkText, linkUrl }) {
  return (
    <article className="flex flex-col items-center text-center m-3 p-0 sm:p-5 w-1/2 sm:w-1/2 md:w-1/3 xl:w-1/5">
      <div className="h-40 md:h-48 lg:h-60 flex flex-col justify-center mb-3">
        <PreviewCompatibleImage
          imageObject={imageObject}
          loading="eager"
          objectFit="contain"
          className="rounded-3xl max-h-full max-w-xs sm:max-w-none"
        />
      </div>
      <BrandButton
        href={linkUrl}
        className="w-full"
      ><RenderMarkdown markdownContent={linkText}/></BrandButton>
    </article>
  );
}

const ImagesAndLinksPage = ({ data }) => {
  return <ImagesAndLinksPageTemplate data={data.markdownRemark.frontmatter} />;
};

export default ImagesAndLinksPage;

export const pageQuery = graphql`
  query ImagesAndLinksPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seoObject {
          domain
          title
          description
          imageObject {
            image {
              childImageSharp {
                resize(width: 1200) {
                  src
                  height
                  width
                }
              }
            }
            alt
          }
          index
        }
        headerObject {
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
            mobileImage {
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
        }
        heroObject {
          title
          imagesAndLinksList {
            imageObject {
              alt
              image {
                childImageSharp {
                  gatsbyImageData(placeholder: NONE)
                }
              }
            }
            linkText
            linkUrl
          }
        }
      }
    }
  }
`;
